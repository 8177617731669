import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { BaseService } from 'src/app/base.service';
import { environment } from 'src/environments/environment';
import { EmailValidator } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import * as $ from 'jquery'




@Component({
  selector: 'app-login-component',
  templateUrl: './login-component.component.html',
  styleUrls: ['./login-component.component.css']
})
export class LoginComponentComponent implements OnInit {
  showMessage = "";
  

  constructor(private router: Router, 
    private sharedService: SharedService, 
    private baseService: BaseService, 
    private appComponent : AppComponent) { }

  email: string;
  password: string;
  submitted = false;
  rememberMeChecked: boolean = false;
  
// private KotakIpStartString = ['163.116.131.','163.116.205.','163.116.199.', '163.116.195.', '163.116.219.', '163.116.196.'];
private KotakIpStartString = ['163.116.205.', '163.116.213.','163.116.199.','163.116.212.','163.116.195.','163.116.214.','163.116.196.','163.116.219.'];
private validKotakIpList = ['162.10.221.28', '162.10.221.29', '162.10.224.6', '162.10.224.5', '162.10.219.5', '162.10.219.6', '162.10.218.42', 
  '162.10.218.43', '162.10.228.28', '162.10.228.29', '24.239.151.103', '24.239.151.103', '24.239.143.105', '24.239.143.106', '24.239.150.107', '24.239.150.108'];

private validCompany : string = "Kotak Wealth";
  user : any;
  result : boolean;  

  ngOnInit(): void {
    this.ray();
    
       $.getJSON("https://api.db-ip.com/v2/free/self", function(data) {		
      console.log(data.ipAddress);
      // Using third Party API to capture client Public IP and using restriction logic
      localStorage.setItem("userIp", data.ipAddress); 
    })
  }

  newMessage() {
    this.showMessage = "";
  }

  onSubmit(form) {

    // this.validKotakIpList.push('116.72.133.77')

    // Adding KOTAK range of IPs to allowed list of IPs
    for(var j = 0; j < this.KotakIpStartString.length; j++){
      var ipStart : string = this.KotakIpStartString[j];
      for(var i = 0; i <= 255; i++){
        var ip : string = ipStart + i;
        this.validKotakIpList.push(ip);
      }
    }
  
    // start of submit process
    this.showMessage = "";
    if (form.invalid) {
      console.log("Invalid")
    }
    else { 
      this.email = form.controls.email.value;
      this.password = form.controls.password.value;
      this.rememberMeChecked = form.controls.rememberMe.value ? true : false
      this.baseService.save(environment.restPath + 'security/v1/signin', { "email": this.email, "password": this.password, "rememberMe": this.rememberMeChecked }).subscribe(
        (response) => {

          var passwordResetCounter = response.user['passwordResetCounter']==undefined?0:response.user['passwordResetCounter']
          localStorage.setItem("token", response["X-AuthToken"]);
          localStorage.setItem("user", JSON.stringify(response["user"]))
          //uncomment to download pdf on localhost:4200
          // document.cookie = "token=" + response["X-AuthToken"]

          // Using third Party API to capture client IP and using restriction logic
          this.user = localStorage.user? JSON.parse(localStorage.user):{};
          
          if(this.user.companyName === this.validCompany 
                  && this.validKotakIpList.indexOf(localStorage.getItem("userIp")) === -1){

                console.log("Invalid IP");
                this.baseService.save(environment.restPath+"security/signout",this.user).subscribe((response)=>{
                  this.appComponent.removeAuthStorage();
                  this.appComponent.showSideBar = false;
                  this.router.navigate(['/login']);            
                });

          }
          
          this.sharedService.loginResult(true);
          // needs additional logic to restrict to new users or only kotak users
          if (passwordResetCounter === 0 ){
            if (response["passwordResetUrl"])
            window.location.href = response["passwordResetUrl"];
          }
          else 
            this.router.navigate(['/upload']);

        }, (error) => {
          if (error.status == 401)
            this.showMessage = "Please Enter Valid Email Address And Password"
          if (error.status == 412){
            // this.showMessage = "Please activate your account (Check your email or contact your company admin)"
            this.showMessage = "Your account is deactivated due to dormancy, please contact your admin for activation."
          }
        });
    }
  }

  gotoSignUp() {
    this.router.navigate(['/signUp']);


  }

  gotoForgotPassword() {
    this.router.navigate(['/forgotPassword']);
  }
  gotoResetPassword() {
    this.router.navigate(['/resetPassword']);
  }

ray(){
  let vid=<HTMLVideoElement>document.getElementById('run');
  vid.muted=true;
  vid.loop=true;
}
  


}
     